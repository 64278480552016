
import Post6Summary from "../summary/Post6Summary";

const Post6 = (lang) => {
	let content
	if (lang === 'rom') {
		const romText = `
# Metafora din greseala

Una dintre cele mai interesante imagini pe care le-am primit de la bunica mea înfățișează un cuplu de prieteni.
In consecinta, nu există nicio legătură emoțională pentru mine, dar totusi imaginea mă afectează in anume sens.

Mi se pare ca atât bărbatul, cât și femeia sunt atât de eleganti, ea cu blana și pălăria drăguță, poșeta mica si cocheta. El are haina lui eleganta și pălăria pusa smechera la unghi. 

Îmi place natura spontană a fotografiei (bravo fotografilor de stradă din București!), 
ea clar că tocmai a pășit pe trotuar, el in mers, ceea ce face fotografia să pară atât de reală și naturală.

<figure>
<img src="./post6/photo.png"
	style="display: block; margin: auto; width: 300px" />
</figure>


Pe spatele fotografiei se menționează numele celor două persoane și un scurt mesaj, descriind activitatea lor ca fiind _hoinareala_  și, partea interesantă, data fotografiei, 11 martie 1935.

Întrebarea atunci este ce cauta svastica pe fundal, în această zi la București?!! In 1935?
Niciuna dintre referințele pe care le-am găsit nu ar putea explica acest lucru.
Îmi pot imagina doar că trebuie să fi fost un oficial german în vizită, ca o vizită de stat și i-au pus steagul. Dar atunci unde este steagul României? Din ce am citit, Garda de Fier, nu era deosebit de activă la acea vreme și avea propriile simboluri.

Evident, oamenii din fotografii erau doar trecători, fotograful s-a concentrat doar pe subiecții lui, dar pentru mine contrastul dintre cuplul relaxat elegant, lipsit de griji, bucurindu-se de viata și semnul de rău augur din spatele lor este o metaforă accidentală a fragilității acelei stări fericite. 
Semnele întunecate sunt acolo, dar ignorate când privim în altă parte.
A vedea acel simbol este oricum neplăcut și neasteptat pentu acea epocă.

Mi se pare foarte interesant contrastul intimplator dintre prim-plan și fundalul fotografiei.
 Va deveni vreuna dintre fotografiile mele vreo metafora accidentale cu simbolurile fatalității evidente în retrospectivă în imagini, altfel, perfect inocente? 

Sper ca nu!			
`
		content = romText
	} else { 
const postText = `
# The accidental metaphor

One of the most interesting images that I got from my grandmother depicts what I understand to be friends of hers.
So there is no emotional connection for me but I find the image affecting all the same.
Both man and woman are so elegant, she with the fur and the cute hat, he with the coat and the rakish angle of his hat. I love the spontaneous nature of the photo( hooray for the street photographers of the Bucharest), she clearly just stepped on the pavement, he is midstep makes it feel so real and natural.

<figure>
<img src="./post6/photo.png"
     style="display: block; margin: auto; width: 300px" />
</figure>


The back of the photo mentions the names of the two people and a short message, describing their activity as hoinareala - rambling, carefree, leisurely walking and, the interesting bit, a date, 11 March 1935.

The question then is what is the swastika doing in the background, on this day in Bucharest?!! 1935? 
None of the references I found could explain this. 
I can only imagine that there must have been some visiting German official, like a state visit and they put the flag up for him.  But then where is the Romanian flag? The local pro Nazi militia, Iron Guard was not particularly active at that time and had its own symbols.
Obviously the people in the photos were just passers by, the photographer just focused on his subjects but for me the contrast between the elegant, carefree, peaceful demeanour of the couple and the ominous sign behind them is an accidental metaphor of fragility of that happy state and the signs of the dark times ahead, there to see but ignored as we look elsewhere. 
Seeing that symbol on the main avenue in Bucharest is quite unpleasant and I certainly did not expect to see it in that era.

I find the contrast between the foreground and the background of the photo quite chilling.

Will any of our photos become some accidental metaphors with the symbols of doom obvious in hindsight in otherwise perfectly innocent images? I so hope not!
    
`
    content = postText
	}


const summary = Post6Summary(lang)
return {
	content,
	linkId: summary.linkId,
	title: summary.cardTitle
}
}

export default Post6;
